import React from 'react'
import './HamburgerMenu.css'
export const HamburgerMenu = () => {
  return (
    <>
      <div className='HambergerMenu'>
        <div>
          <div/>
          <div/>
          <div/>
        </div>
      </div>
    </>
  )
}
