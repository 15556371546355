/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 bottleAndPlant.glb 
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export function BottleAndPlant(props) {
  const { nodes, materials } = useGLTF('assets/models/bottleAndPlant.glb')
  return (
    <group {...props} dispose={null} scale={6}>
      <mesh geometry={nodes.olive.geometry} material={materials.olive} position={[0.456, -0.466, 0.12]} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.bottle.geometry} material={materials.bottle} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.popeye.geometry} material={materials.popeye} position={[0.085, -0.135, -0.143]} rotation={[Math.PI / 2, 0, 0]} />
      <mesh geometry={nodes.tulip.geometry} material={materials.tulip} position={[-0.426, -0.622, -0.075]} rotation={[1.571, 1.564, 0]} />
    </group>
  )
}

useGLTF.preload('assets/models/bottleAndPlant.glb')
