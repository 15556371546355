import React from 'react'
import { CanvasComponent } from '../components/CanvasComponent'

export const Home = () => {
  return (
    <>
      <CanvasComponent></CanvasComponent>
    </>
    
  )
}
