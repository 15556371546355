import React, { useState } from 'react'
import { Canvas, useThree } from '@react-three/fiber'
import { ScrollControls, Scroll } from '@react-three/drei'
import { Model } from './Hummingbirdanimation.jsx'
import { BottleAndPlant } from './BottleAndPlant.jsx'

const Scene = () =>{
    const [isClicked, setIsClicked] = useState(false);
    const { width, height } = useThree((state) => state.viewport)
    return (
        <>
            <ScrollControls damping={0.2} maxSpeed={0.5} pages={3}>
                <directionalLight color="orange" position={[0, 2, 2]} intensity={1.8}/>
                <ambientLight intensity={2} />
                <group  position={[0, 0, -100]}>
                    <group rotation={[0, 0, 0]}>
                            <Model scale={1} position={[0, 0, 0]} rotation={[0, 0, 0]}/>
                    </group>
                </group>
                <Scroll>
                    <group position={[0, 0, -100]}>
                        <BottleAndPlant/>
                    </group>
                </Scroll>
                <Scroll html>
                    <h1 style={{ position: 'absolute', top: '50vh', left: '3em' }}>Hummingbird<br/>and Botanical</h1>
                    <h1 style={{ position: 'absolute', top: '120vh', left: '60vw' }}>//</h1>
                    <h1 style={{ position: 'absolute', top: '198.5vh', left: '0.5vw', fontSize: '40vw' }}>home</h1>
                </Scroll>
            </ScrollControls>
        </>
    )
}
export const CanvasComponent = () => {
    
    const canvasContainer = {
        style: {
            left: 0,
            top: 0,
            width: "100vw",
            height: "100vh",
        }
    }

    return (
        <div style={canvasContainer.style}>
            <Canvas camera={{ fov: 10, position: [0, 0, 0] }}>
                <Scene></Scene>
            </Canvas>
        </div>
    )
}
