import React from 'react'
import { Navigation } from './Navigation'
import { HamburgerMenu } from './HamburgerMenu'
import './Header.css'

export const Header = () => {

  return (
    <header className='header'>
      <Navigation />
      <HamburgerMenu />
    </header>
  )
}
