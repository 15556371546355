import logo from './logo.svg';
import './App.css';
import { Header } from './components/Header';
import { Route, Routes } from 'react-router-dom';
import { Home } from './routers/Home';
import { BePopeye } from './routers/BePopeye';
import { BeOlieve } from './routers/BeOlieve';
import { Notfound } from './routers/Notfound';

function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path='/' element={ <Home /> }/>
        <Route path='/bepopeye' element={ <BePopeye /> }/>
        <Route path='/beolive' element={ < BeOlieve/> }/>
        <Route path='*' element={ <Notfound/> } />
      </Routes>
    </>
  );
}

export default App;
