/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Command: npx gltfjsx@6.2.16 hummingbirdanimation.glb 
*/

import React, { useRef, useEffect, useCallback } from 'react'
import { useFrame } from "@react-three/fiber"
import { useGLTF, useAnimations, useScroll } from '@react-three/drei'

export function Model(props) {
  const scroll = useScroll()
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('assets/models/hummingbirdanimation.glb')
  const { actions } = useAnimations(animations, group)
 
  const handlePointerEnter = useCallback(()=>{
    actions.hiding.reset().play().fadeIn(0.5)
  },[actions.hiding])
  const handlePointerLeave = useCallback(()=>{
    actions.hiding.fadeOut(0.5)
  },[actions.hiding])

  useEffect(()=> {
    actions.hovering.reset().play()
  }, [actions.hovering])

  useEffect(()=> {
    void (actions.moving.reset().play().paused = true)
  }, [actions.moving])

  useFrame(() => {
    (actions.moving.time = actions.moving.getClip().duration * scroll.offset)
  })

  return (
    <group 
      ref={group} {...props} 
      dispose={null}
      onPointerEnter={handlePointerEnter} 
      onPointerLeave={handlePointerLeave}
    >
      <group name="Scene">
        <group name="Armature" position={[4.305, 4.305, 0]} rotation={[0, -Math.PI / 2, 0]}>
          <primitive object={nodes.Bone} />
          <primitive object={nodes.Bone001} />
          <skinnedMesh name="hummingbird_body" geometry={nodes.hummingbird_body.geometry} material={materials['Material.004']} skeleton={nodes.hummingbird_body.skeleton} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('assets/models/hummingbirdanimation.glb')
