import React from 'react'
import { Link } from 'react-router-dom'
import './Navigation.css'

export const Navigation = () => {
  return (
    <div className='nav'>
        <ul>
          <li>
              <Link to='/'>TOP</Link>
          </li>
          <li>
              <Link to='/bepopeye'>MENS</Link>
          </li>
          <li>
              <Link to='/beolive'>WOMENS</Link>
          </li>
        </ul>
    </div>
  )
}
